.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  opacity: 0;
  z-index: 999;
  visibility: hidden;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;

  .sidebarContent {
    width: 100%;
    max-width: 300px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 1.5rem 1rem;
    transform: translate3d(-300px, 0, 0);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;

    .navbar {
      padding: 2rem 0;
    }

    .navItem {
      display: flex;
      align-items: center;
      padding: 0.625rem 1rem;
      text-decoration: none;
      color: #212121;
      border-radius: 10px;
      font-weight: 500;
      font-size: 1rem;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &.active {
        background-color: rgba(0, 0, 0, 0.08);
      }

      + .navItem {
        margin-top: 4px;
      }
    }

    @media (min-width: 768px) {
      transform: translate3d(0, 0, 0);
    }
  }

  .backdrop {
    position: absolute;
    left: 300px;
    top: 0;
    right: 0;
    bottom: 0;

    @media (min-width: 768px) {
      display: none;
    }
  }

  &.opened {
    opacity: 1;
    visibility: visible;

    .sidebarContent {
      transform: translate3d(0, 0, 0);
    }
  }

  @media (min-width: 768px) {
    right: unset;
    opacity: 1;
    visibility: visible;
    max-width: 300px;
    width: 100%;
    background-color: transparent;
  }

  .logoLink {
    display: block;
    cursor: pointer;
    text-decoration: none;
  }
}

.logoutItem {
  margin-top: auto;
  cursor: pointer;
}
