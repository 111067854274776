.row {
  margin: 0 -0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.rowCols3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}

.col {
  padding: 0 0.5rem;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.half {
  padding: 0.5rem;
  flex: 1 0 100%;
  max-width: 100%;

  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
