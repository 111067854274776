.navbar {
  flex-shrink: 0;
  padding: 0.5rem;
  height: 64px;
  background-color: #212529;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .iconButton {
    padding: 0.5rem;
    margin-right: 0.5rem;
  }

  .icon {
    color: #fff;
    width: 32px;
    height: 32px;
  }

  .falseIcon {
    width: 48px;
    height: 48px;
  }

  .profile {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;

    .userInfo {
      margin-left: 0.5rem;
      line-height: 1.2;
    }

    .userName {
      font-weight: 600;
    }

    .userUsername {
      color: #777;
      font-size: 14px;
    }

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  @media (min-width: 768px) {
    justify-content: flex-end;
    background-color: transparent;
    border-bottom: 1px solid #ddd;
    margin: 0 2rem;

    .logo,
    .iconButton,
    .falseIcon {
      display: none;
    }
  }
}
