.dashboardContainer {
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    padding: 1rem;

    @media (min-width: 768px) {
      padding: 2rem;
    }
  }

  @media (min-width: 768px) {
    padding-left: 300px;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
