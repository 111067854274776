.bodyBackground {
  background-color: #212529;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  margin-top: 2rem;
}
