.searchBar {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #eee;
  background-clip: padding-box;
  border: 2px solid #eee;
  appearance: none;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    background-color 0.15s ease-in-out;

  &:focus {
    border-color: #212529;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }

  background-image: url("../../assets/icons/search.svg");
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: 0.5rem center;

  padding: 0.375rem 0.75rem;
  padding-left: calc(0.5rem + 22px + 0.375rem);
}
