.alert {
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--color-eee);
  color: var(--color-333);
}

.error {
  background-color: #f44336;
  color: #fff;
}

.success {
  background-color: #4caf50;
  color: #fff;
}
